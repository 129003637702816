<template>
  <div
    class="matching-filter d-flex justify-content-between align-items-center"
  >
    <div class="matching-filter-left">
      <h3>62 sellers match your inquiries adasd</h3>
    </div>
    <div class="matching-filter-right d-flex">
      <div class="matching-filter-right-item">
        <button
          class="btn btn-outline-secondary btn-xs d-flex justfiy-content-between align-items-center"
        >
          <div>
            {{ GET_USER_INFO.locations.find((x) => x.default).address.city || 'unnamed' }} /
            {{ GET_USER_INFO.locations.find((x) => x.default).address.country }}
          </div>
          <div class="ml-5">
            <v-icon :icon="['fac', 'location']"></v-icon>
          </div>
        </button>
      </div>
      <div class="matching-filter-right-item">
        <button
          class="btn btn-outline-secondary btn-xs d-flex justfiy-content-between align-items-center"
        >
          <div>Filters</div>
          <div class="ml-5">
            <v-icon :icon="'sliders-h'" rotation="90" />
          </div>
        </button>
      </div>
      <div class="matching-filter-right-item">
        <a
          @click.prevent="changeView('map')"
          href="#"
          class="icon-btn"
          :class="{ active: view === 'map' }"
        >
          <v-icon :icon="['fac', 'location']"></v-icon>
        </a>
      </div>
      <div class="matching-filter-right-item">
        <a
          @click.prevent="changeView('card')"
          href="#"
          class="icon-btn"
          :class="{ active: view === 'card' }"
        >
          <v-icon icon="border-all"></v-icon>
        </a>
      </div>
      <div class="matching-filter-right-item">
        <a
          @click.prevent="changeView('list')"
          href="#"
          class="icon-btn"
          :class="{ active: view === 'list' }"
        >
          <v-icon icon="list"></v-icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
