<template>
  <div class="container-fluid p-0 m-0">
    <div class="row no-gutters">
      <div class="col-12">
        <app-header />
        <div class="app-content">
          <router-view  />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appHeader from "@/components/app/Header.vue";
import appFilter from "@/components/app/matching/component/filter.vue";
export default {
  components: {
    appHeader,
    appFilter,
  },
};
</script>

<style>


</style>
